@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap');

@font-face {
  font-display: auto;
  font-family: Fonarto;
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/fonarto-light.woff2) format("woff2")
}

@font-face {
  font-display: auto;
  font-family: Fonarto;
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/fonarto-regular.woff2) format("woff2")
}

@font-face {
  font-display: auto;
  font-family: Fonarto;
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/fonarto-bold.woff2) format("woff2")
}