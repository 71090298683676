body {
  margin: 0;
}

body, input, button {
  /*font-weight: 400;
  font-family: "Chakra Petch", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

#root {
  overflow-x: hidden;
}

/*button:focus {
  outline: 2px solid var(--cds-interactive-01, #0f62fe);
  outline-offset: -2px;
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}